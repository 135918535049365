import { Text } from "@clipboard-health/ui-react";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { Skeleton, Stack } from "@mui/material";

export function FacilityDetailsSkeleton() {
  return (
    <Stack spacing={1} paddingTop={1}>
      <Stack direction="row" spacing={2} alignItems="start">
        <LocationOnIcon color="action" />
        <Stack spacing={0.5}>
          <Text variant="body2" color="inherit">
            <Skeleton width={200} />
          </Text>
          <Text variant="body2" color="inherit">
            <Skeleton width={200} />
          </Text>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <PhoneOutlinedIcon htmlColor="#0000008F" />
        <Text variant="body2" color="inherit">
          <Skeleton width={200} />
        </Text>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <DirectionsCarFilledOutlinedIcon htmlColor="#0000008F" />
        <Text variant="body2" color="inherit">
          <Skeleton width={200} />
        </Text>
      </Stack>
    </Stack>
  );
}
