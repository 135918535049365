import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import pluralize from "pluralize";

interface ShiftInviteMissingCoreRequiredDocumentsDialogProps {
  modalState: UseModalState;
  missingDocuments: string[];
}

export function ShiftInviteMissingCoreRequiredDocumentsDialog(
  props: Readonly<ShiftInviteMissingCoreRequiredDocumentsDialogProps>
) {
  const { modalState, missingDocuments } = props;
  return (
    <CommonDialog
      modalState={modalState}
      title={`You are missing your ${missingDocuments[0]} document.`}
      actions={
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            modalState.closeModal();
          }}
        >
          Go Back
        </Button>
      }
    >
      <Stack spacing={1}>
        <Text variant="body2">
          Your {missingDocuments.join(", ")} {pluralize("document", missingDocuments.length)}{" "}
          {pluralize("is", missingDocuments.length)} expired or missing. You can not accept this
          invitation until you upload your {missingDocuments.join(", ")}{" "}
          {pluralize("document", missingDocuments.length)}.
        </Text>
        <Text variant="body2">
          If you have questions about documents that are required, please reach out to support via
          In App Chat.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
