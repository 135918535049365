import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Stack } from "@mui/material";

import { ShiftInviteStatus } from "./types";

export interface ShiftInviteCardProps {
  onAcceptShiftInvite: () => void;
  onDeclineShiftInvite: () => void;
  isLoading?: boolean;
  shiftInviteAction?: ShiftInviteStatus;
}

export function ShiftInviteCardAction(props: ShiftInviteCardProps) {
  const { onAcceptShiftInvite, onDeclineShiftInvite, isLoading, shiftInviteAction } = props;
  return (
    <Stack direction="row">
      <Button
        variant="contained"
        size="small"
        startIcon={
          isLoading && shiftInviteAction === ShiftInviteStatus.ACCEPTED ? (
            <CircularProgress size={18} sx={{ color: "inherit" }} />
          ) : (
            <CheckIcon />
          )
        }
        disabled={isLoading}
        onClick={() => {
          onAcceptShiftInvite();
        }}
      >
        Accept
      </Button>
      <Button
        size="small"
        startIcon={
          isLoading && shiftInviteAction === ShiftInviteStatus.DECLINED ? (
            <CircularProgress size={18} sx={{ color: "inherit" }} />
          ) : (
            <CloseIcon />
          )
        }
        disabled={isLoading}
        onClick={() => {
          onDeclineShiftInvite();
        }}
      >
        Decline
      </Button>
    </Stack>
  );
}
