import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import pluralize from "pluralize";

interface ShiftInviteMissingFacilityRequiredDocumentsDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  missingDocuments: string[];
}

export function ShiftInviteMissingFacilityRequiredDocumentsDialog(
  props: Readonly<ShiftInviteMissingFacilityRequiredDocumentsDialogProps>
) {
  const { modalState, onConfirm, missingDocuments } = props;
  return (
    <CommonDialog
      modalState={modalState}
      title={`You are missing your ${missingDocuments[0]} document.`}
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Book Shift
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text variant="body2">
          Your {missingDocuments.join(", ")} {pluralize("document", missingDocuments.length)}{" "}
          {pluralize("is", missingDocuments.length)} expired or missing. You can still accept this
          invitation, but please be sure to upload your {missingDocuments.join(", ")}{" "}
          {pluralize("document", missingDocuments.length)} as soon as you can.
        </Text>
        <Text variant="body2">
          If you have questions about documents that are required, please reach out to support via
          In App Chat.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
