import { Stack } from "@mui/material";
import { ShiftInviteItem } from "@src/appV2/Shifts/ShiftInvites/ShiftInviteItem";
import { type Worker } from "@src/appV2/Worker/api/types";

import { ShiftInviteItemProvider } from "./context/shiftInviteItemContext";
import { type ShiftInvite } from "./types";

interface ShiftInvitesProps {
  shiftInvites: ShiftInvite[];
  worker: Worker;
}

export function ShiftInvites(props: ShiftInvitesProps) {
  const { shiftInvites, worker } = props;

  return (
    <Stack spacing={2}>
      {shiftInvites.map((shiftInvite) => (
        <ShiftInviteItemProvider key={shiftInvite.id}>
          <ShiftInviteItem shiftInvite={shiftInvite} worker={worker} />
        </ShiftInviteItemProvider>
      ))}
    </Stack>
  );
}
