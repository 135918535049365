import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";
import { type Worker } from "@src/appV2/Worker/api/types";

import { useShiftInviteActions } from "./api/useShiftInviteActions";
import { ShiftInviteCard } from "./ShiftInviteCard";
import { ShiftInviteItemDialog } from "./ShiftInviteItemDialog";

interface ShiftInviteItemProps {
  shiftInvite: ShiftInvite;
  worker: Worker;
}
export function ShiftInviteItem(props: ShiftInviteItemProps) {
  const { shiftInvite, worker } = props;
  const { id: shiftInviteId } = shiftInvite;
  const {
    acceptShiftInvite,
    declineShiftInvite,
    isLoadingAcceptOrDecline,
    acceptOrDeclineRequest,
  } = useShiftInviteActions();

  return (
    <>
      <ShiftInviteCard
        shiftInvite={shiftInvite}
        isLoading={isLoadingAcceptOrDecline}
        shiftInviteAction={acceptOrDeclineRequest?.status}
        worker={worker}
        onAcceptShiftInvite={async () => {
          await acceptShiftInvite({ shiftInvite });
        }}
        onDeclineShiftInvite={async () => {
          await declineShiftInvite({ shiftInviteId });
        }}
      />
      <ShiftInviteItemDialog shiftInvite={shiftInvite} />
    </>
  );
}
