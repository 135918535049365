import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { BannerLink } from "@src/appV2/lib/Alert";
import { logEvent } from "@src/appV2/lib/analytics";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";

import { useAgentPendingShiftInvites } from "./api/useAgentPendingShiftInvites";

export function PendingShiftInvitesBanner() {
  const { data: shiftInvites, isSuccess } = useAgentPendingShiftInvites();
  const { pathname } = useLocation();
  const pendingShiftInvites = isSuccess ? shiftInvites.data : [];
  const shiftInviteCount = pendingShiftInvites.length;

  return shiftInviteCount > 0 ? (
    <BannerLink
      title={`You have ${shiftInviteCount} pending shift ${pluralize(
        "invite",
        shiftInviteCount
      )} to review.`}
      to={`${RootPaths.APP_V2_HOME}/myShifts/shiftInvites`}
      severity="info"
      onClick={() => {
        logEvent(APP_V2_APP_EVENTS.SHIFT_INVITE_BANNER_CLICK, {
          pageName: capitalize(pathname.split("/").pop()),
          shiftInviteCount,
        });
      }}
    >
      {shiftInviteCount === 1
        ? "Tap to review and accept this invite before it expires."
        : "Tap to review and accept these invites before they expire."}
    </BannerLink>
  ) : null;
}
