import { Text, type UseModalState } from "@clipboard-health/ui-react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Button, List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import pluralize from "pluralize";

import { type ShiftInvite } from "../types";

interface ShiftInviteConflictingInviteDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  onCancel: () => any;
  conflictingInvites: ShiftInvite[];
}

export function ShiftInviteConflictingInviteDialog(props: ShiftInviteConflictingInviteDialogProps) {
  const { modalState, onConfirm, onCancel, conflictingInvites } = props;
  const pluralizedInvite = pluralize("invite", conflictingInvites.length);
  return (
    <CommonDialog
      modalState={modalState}
      title={`You have ${conflictingInvites.length} pending shift ${pluralizedInvite}.`}
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Accept Anyway
          </Button>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Back to Invites
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text variant="body2">
          If you accept this shift invite, your pending {pluralizedInvite} will be automatically
          declined.
        </Text>
        <List>
          {conflictingInvites.map((invite) => (
            <ListItem key={invite.id}>
              <ListItemIcon>
                <ApartmentIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                <Text variant="body2">
                  {invite.attributes.facility.name} (
                  {formatDollarsAsUsd(invite.attributes.shiftDetails.pay)} per hour)
                </Text>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Stack>
    </CommonDialog>
  );
}
