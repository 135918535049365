import { formatDate, formatTime } from "@clipboard-health/date-time";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

import { type Shift } from "../../Shift/types";
import { type ShiftInvite } from "../types";

interface ShiftInviteConflictDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  conflictingShift: Shift;
  shiftInvite: ShiftInvite;
}

export function ShiftInviteConflictDialog(props: ShiftInviteConflictDialogProps) {
  const { modalState, onConfirm, conflictingShift, shiftInvite } = props;
  const timeZone = conflictingShift.facility.tmz;
  const invitedFacilityName = shiftInvite.attributes.facility.name;
  const conflictedFacilityName = conflictingShift.facility.name;

  return (
    <CommonDialog
      modalState={modalState}
      title="You're booked for a conflicting shift."
      actions={
        <>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Go Back
          </Button>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Go to My Shifts
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text variant="body2">
          The shift you were invited to at {invitedFacilityName} conflicts with your booked shift at{" "}
          {conflictedFacilityName}.
        </Text>
        <Text variant="body2">
          If this invite is for a `double` please note that the first shifts end time must not
          overlap with the second shifts start time.
        </Text>
        <Text variant="body2">
          You can ask the facility to send you another invite, or you can cancel your{" "}
          {formatDate(conflictingShift.start, {
            timeZone,
          })}{" "}
          {formatTime(conflictingShift.start, { timeZone })} shift at {conflictedFacilityName} and
          accept this invite.
        </Text>
        <Text variant="body2">
          Attendance Score Points will be deducted from your score for any cancellations that you
          make.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
