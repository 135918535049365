import { Card, CardContent, Skeleton, Stack } from "@mui/material";

import { FacilityDetailsSkeleton } from "./FacilityDetailsSkeleton";

export function ShiftInviteCardSkeleton() {
  return (
    <Card elevation={3} aria-label="Shift invite card skeleton">
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Skeleton width={150} />
            <Skeleton width={50} />
          </Stack>
          <Skeleton width={150} />
          <Stack direction="row">
            <Skeleton width={50} />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton width={200} />
          </Stack>
          <FacilityDetailsSkeleton />
        </Stack>
      </CardContent>
    </Card>
  );
}
