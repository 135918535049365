import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface ShiftInviteOverworkingDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  onCancel: () => any;
}

export function ShiftInviteOverworkingDialog(props: ShiftInviteOverworkingDialogProps) {
  const { modalState, onConfirm, onCancel } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Potential Overworking"
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Book Anyway
          </Button>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Back to Invites
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text variant="body2">
          Accepting this invite will result in you being booked for more than 16.5 consecutive hours
          of shifts through Clipboard Health.
        </Text>
        <Text variant="body2">
          You can choose to accept or decline this invite, or cancel another shift if you do not
          want to work more than 16.5 consecutive hours.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
